@if (hasMessage) {
  <cft-snack-bar
    class="m-sm"
    [color]="color"
    [icon]="icon"
    [message]="message | bgoNullToUndefined"
    [hasCloseButton]="options.closeButton || false"
    [progress]="width"
    (closed)="onClose($event)"
  ></cft-snack-bar>
}
