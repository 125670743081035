export const environment = {
  sentry: {
    enabled: true,
  },
  analytics: {
    enabled: true,
  },
  i18n: {
    baseUrl: 'https://i18n.buildigo.ch/i18n/web-ui/master',
    prodMode: true,
  },
  logging: {
    pretty: false, // incompatible with lambda
  },
}
