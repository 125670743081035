// eslint-disable max-len
import {BrowserModule, provideClientHydration} from '@angular/platform-browser'
import {APP_ID, inject, NgModule} from '@angular/core'
import {routes} from './app.routes'
import {AppComponent} from './app.component'
import {provideHttpClient, withFetch, withInterceptors, withJsonpSupport} from '@angular/common/http'
import {ApolloModule} from 'apollo-angular'
import {registerLocaleData} from '@angular/common'
import {TranslocoService} from '@ngneat/transloco'
import {provideApolloOptions} from './core/graphql/apollo-factory'
import {FontAwesomeModule} from '@fortawesome/angular-fontawesome'
import {TranslocoRootModule} from './core/i18n/transloco-root.module'
import {DialogModule} from './craft/dialog/dialog.module'
import {I18nService} from './craft/i18n/i18n.service'
import {provideImageApiLoader} from './core/picture/loaders/loader'
import {
  ActivatedRouteSnapshot,
  BaseRouteReuseStrategy,
  IsActiveMatchOptions,
  provideRouter,
  Router,
  RouteReuseStrategy,
  RouterModule,
  ViewTransitionInfo,
  withEnabledBlockingInitialNavigation,
  withInMemoryScrolling,
  withViewTransitions,
} from '@angular/router'
import {ToastModule} from './shared/components/toast/toast.module'
import {LoadingProgressComponent} from './core/components/loading/loading-progress.component'
import {LayoutComponent} from './core/components/layout/layout.component'
import {provideLocaleId} from './core/i18n/providers'
import {provideErrorHandler, provideErrorMonitoring} from './core/error/providers'
import {AnalyticsComponent} from './core/analytics/analytics.component'
import localeDeCH from '@angular/common/locales/de-CH'
import localeFrCH from '@angular/common/locales/fr-CH'
import localeItCH from '@angular/common/locales/it-CH'
import {AuthInterceptor} from './core/http/auth.interceptor'
import {ErrorInterceptorFactory} from './core/http/error.interceptor'
import {I18nInterceptor} from './core/http/i18n.interceptor'
import {PreventInterceptor} from './core/http/prevent.interceptor'
import {provideAnimationsAsync} from '@angular/platform-browser/animations/async'
import {provideLogging} from './core/logging/providers'

registerLocaleData(localeDeCH)
registerLocaleData(localeFrCH)
registerLocaleData(localeItCH)

// eslint-enable max-len

@NgModule({
  bootstrap: [AppComponent],
  declarations: [AppComponent, LoadingProgressComponent],
  imports: [
    RouterModule,
    BrowserModule,
    TranslocoRootModule,
    FontAwesomeModule,
    ApolloModule,
    ToastModule,
    DialogModule,
    LayoutComponent,
    AnalyticsComponent,
  ],
  providers: [
    // in certain use cases Angular should not reuse routes i.e. we should have the ability to switch it off
    // e.g. navigating between pages where just the route param changes: https://angular.io/api/router/BaseRouteReuseStrategy#baseroutereusestrategy
    {
      provide: RouteReuseStrategy,
      useClass: class CustomRouteReuseStrategy extends BaseRouteReuseStrategy {
        shouldReuseRoute(future: ActivatedRouteSnapshot, curr: ActivatedRouteSnapshot): boolean {
          return 'shouldReuse' in future.data ? future.data.shouldReuse : super.shouldReuseRoute(future, curr)
        }
      },
    },
    {provide: APP_ID, useValue: 'bgo-ui'},
    provideLocaleId(),
    provideRouter(
      routes,
      withEnabledBlockingInitialNavigation(),
      withInMemoryScrolling({anchorScrolling: 'enabled', scrollPositionRestoration: 'enabled'}),
      withViewTransitions({
        skipInitialTransition: true,
        onViewTransitionCreated: viewTransitionController,
      }),
    ),
    provideHttpClient(
      withFetch(),
      withJsonpSupport(),
      withInterceptors([AuthInterceptor, I18nInterceptor, ErrorInterceptorFactory(3, 2000), PreventInterceptor]),
    ),
    provideClientHydration(),
    provideLogging(),
    provideErrorMonitoring(),
    provideErrorHandler(),
    provideImageApiLoader(),
    provideApolloOptions(),
    provideAnimationsAsync(),
  ],
})
export class AppModule {
  constructor(
    private i18n: I18nService,
    translocoService: TranslocoService,
  ) {
    this.i18n.override(translocoService)
  }
}

function viewTransitionController(info: ViewTransitionInfo) {
  const router = inject(Router)
  const targetUrl = router.getCurrentNavigation()!.finalUrl!
  // skip the transition if the only thing changing is the fragment and queryParams
  const config: IsActiveMatchOptions = {
    paths: 'exact',
    matrixParams: 'exact',
    fragment: 'ignored',
    queryParams: 'ignored',
  }

  if (router.isActive(targetUrl, config)) {
    info.transition.skipTransition()
  }
}
