import {HomePageFieldsFragment} from '../../../../__generated__/datocms.types'
import {LinkService} from '../../i18n/link.service'
import {HomePage, HomePageContent} from '../types/home-page.types'
import {validateDatoCmsTypes} from './common.serde'
import {parseHowItWorksSection} from './how-it-works-section.serde'
import {parseIndustrySection} from './industry-section.serde'
import {parseInspirationSection} from './inspiration-section.serde'
import {parsePartnerSection} from './partner-section.serde'
import {parsePromotionalBannerSection} from './promotional-banner-section.serde'
import {parseReviewSection} from './review-section.serde'
import {parseServicesSection} from './services-section.serde'
import {parseSupportSection} from './support-section.serde'
import {parseKnowledgeSection} from './knowledge-section.serde'
import {parsePresentationSection} from './presentation-section.serde'
import {parseContentSection} from './content-section.serde'

export function parseHomePage(linkService: LinkService, e: HomePageFieldsFragment): HomePage {
  const entry = validateDatoCmsTypes(e)
  const page = validateDatoCmsTypes(entry)
  const content: HomePageContent[] = page.content
    ?.map((c): HomePageContent | undefined => {
      switch (c?.__typename) {
        case 'HowItWorksSectionModelRecord':
          return parseHowItWorksSection(c)
        case 'IndustrySectionModelRecord':
          return parseIndustrySection(linkService, c)
        case 'InspirationSectionModelRecord':
          return parseInspirationSection(linkService, c)
        case 'PartnerSectionModelRecord':
          return parsePartnerSection(linkService, c)
        case 'PromotionBannerModelRecord':
          return parsePromotionalBannerSection(c)
        case 'ReviewSectionModelRecord':
          return parseReviewSection(c)
        case 'ServicesSectionModelRecord':
          return parseServicesSection(c)
        case 'SupportSectionModelRecord':
          return parseSupportSection(c)
        case 'KnowledgeSectionModelRecord':
          return parseKnowledgeSection(c)
        case 'PresentationSectionModelRecord':
          return parsePresentationSection(c)
        case 'FieldContentSectionModelRecord':
          return parseContentSection(c)
        default: {
          return undefined
        }
      }
    })
    .filter((c): c is HomePageContent => !!c)

  return {
    content: content || [],
  }
}
