import {Component} from '@angular/core'
import {OptableLayout} from '../../../core/components/layout/optable-layout.interface'
import {Observable, of, zip, zipWith} from 'rxjs'
import {HasStructuredData, PageComponent, PageMetadata} from '../../../core/seo/page-metadata'
import {TranslocoService} from '@ngneat/transloco'
import {ConfigService} from '../../../core/config/config.service'
import {getSeoUrl} from '../../../core/seo/util'
import {LinkService} from '../../../core/i18n/link.service'
import {PUBLIC_ROUTES} from '../../../shared/routing/public.routes'
import {map} from 'rxjs/operators'
import {ActivatedRoute} from '@angular/router'
import {HomePage} from '../../../core/content/types/home-page.types'
import {transformBuildigoImageUrl} from '../../../core/picture/loaders/buildigo-image-api'
import {Organization, WithContext} from 'schema-dts'
import {HomePageTypeKey, HomePageTypes} from './home-page.types'

@Component({
  templateUrl: './home-page.component.html',
})
export class HomePageComponent extends PageComponent implements OptableLayout, HasStructuredData<Organization> {
  readonly optOutOfLayout = true
  readonly hideOfferingSearchInNavbar = true
  readonly transparentNavbar = true

  readonly page = this.route.snapshot.data['content'] as HomePage
  // disable indexing for mobiliar home page
  private readonly seo = this.route.snapshot.data[HomePageTypeKey] !== HomePageTypes.Mobiliar

  constructor(
    private readonly localeService: TranslocoService,
    private readonly configService: ConfigService,
    private readonly linkService: LinkService,
    private readonly route: ActivatedRoute,
  ) {
    super()
  }

  navigateToAboutUsPage(): Promise<boolean> {
    return this.linkService.navigateTo(PUBLIC_ROUTES.ABOUT_US)
  }

  openSearch(): void {
    this.linkService.navigateTo(PUBLIC_ROUTES.SEARCH)
  }

  getStructuredData$(): Observable<WithContext<Organization> | undefined> {
    if (!this.seo) {
      return of(undefined)
    }
    return this.localeService.selectTranslate('homepage.metadata.description').pipe(
      map((description): WithContext<Organization> => {
        return {
          '@context': 'https://schema.org',
          '@type': 'Organization',
          name: 'buildigo.ch',
          alternateName: 'Buildigo',
          description,
          url: 'https://www.buildigo.ch/',
          sameAs: [
            'https://www.instagram.com/buildigo.ch/',
            'https://www.facebook.com/buildigo/',
            'https://twitter.com/buildigo',
            'https://www.linkedin.com/company/buildigo.com/',
          ],
          address: {
            '@type': 'PostalAddress',
            streetAddress: 'Schwarzenburgstrasse 12A',
            addressLocality: 'Bern',
            postalCode: '3007',
            addressCountry: 'CH',
          },
          logo: getSeoUrl(this.configService.config, '/assets/images/buildigo-icon-transparent.png'),
        }
      }),
    )
  }

  getPageMetadata(): Observable<PageMetadata> {
    if (!this.seo) {
      return of({
        doNotIndexPage: true,
      })
    }
    return zip(
      this.localeService.selectTranslate('homepage.metadata.title'),
      this.localeService.selectTranslate('homepage.metadata.description'),
    ).pipe(
      map(([title, description]) => {
        return {
          title,
          description,
          imageUrl: getSeoUrl(
            this.configService.config,
            transformBuildigoImageUrl('/assets/images/open-graph/home.jpg', {width: 1280}),
          ),
        }
      }),
      zipWith(this.getStructuredData$()),
      map(([metadata, structuredData]) => ({...metadata, structuredData})),
    )
  }
}
