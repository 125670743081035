<ng-container *transloco="let t">
  <div class="flex h-5.5 overflow-hidden rounded-l-full">
    <a
      class="flex items-center bg-grey-50 py-[0.625rem] pl-[1.5rem] pr-[0.875rem] hover:bg-grey-100"
      [routerLink]="linkService.homePath"
      aria-label="Home"
    >
      <img class="buildigo-logo" [ngSrc]="'assets/images/buildigo.svg'" alt="" width="80" height="30" priority />
      <img
        class="buildigo-logo--icon"
        [ngSrc]="'assets/images/buildigo-icon-transparent.svg'"
        alt=""
        width="20"
        height="20"
        priority
      />
    </a>
  </div>
  <div class="group">
    <button
      [routerLink]="searchPath"
      type="button"
      class="flex h-[2.75rem] items-center gap-xs overflow-hidden rounded-r-full border-l border-grey-500 bg-grey-50 pl-[0.875rem] pr-[1rem] hover:bg-grey-100"
      aria-labelledby="search-label"
    >
      <fa-icon [icon]="searchIcon"></fa-icon>
      <span
        class="invisible box-border max-w-0 text-grey-700 transition-all sm:group-hover:visible sm:group-hover:max-w-[10rem] sm:group-hover:pr-[0.5rem]"
        id="search-label"
      >
        {{ t('navbar.search') }}
      </span>
    </button>
    <div
      class="mobiliar-logo invisible absolute left-[7.5rem] top-0 hidden h-full w-full flex-col justify-center transition-opacity sm:flex sm:group-hover:opacity-0"
    >
      <span class="-mb-[.25rem] whitespace-nowrap text-2xs uppercase tracking-wide">{{ t('navbar.aCompanyOf') }}</span>
      <bgo-mobiliar-logo size="2xs"></bgo-mobiliar-logo>
    </div>
  </div>
</ng-container>
