import {ChangeDetectionStrategy, Component} from '@angular/core'
import {LinkService} from '../../../../i18n/link.service'
import {faSearch} from '@fortawesome/pro-solid-svg-icons/faSearch'

@Component({
  selector: 'bgo-navbar-logo',
  templateUrl: './navbar-logo.component.html',
  changeDetection: ChangeDetectionStrategy.OnPush,
  styles: `
    :host {
      @apply relative flex min-w-[7.25rem];
    }
    .buildigo-logo {
      @apply hidden sm:inline;
      @apply cursor-pointer pt-2xs;
    }
    :host-context(.layout-navbar--scrolled) .buildigo-logo {
      @apply h-0 w-0 sm:hidden;
    }
    .buildigo-logo--icon {
      @apply cursor-pointer sm:hidden;
    }
    :host-context(.layout-navbar--scrolled) .buildigo-logo--icon {
      @apply cursor-pointer sm:inline;
    }
    .mobiliar-logo {
      @apply invisible;
    }
    :host-context(.layout-navbar--scrolled) .mobiliar-logo {
      @apply visible;
    }
  `,
})
export class NavbarLogoComponent {
  protected readonly searchIcon = faSearch
  protected readonly searchPath = this.linkService.searchPath

  constructor(protected readonly linkService: LinkService) {}
}
